<template>
  <div>
    <div class="recharge">
      <p>请使用微信扫描下方二维码支付</p>
      <canvas id="canvas"></canvas>
      <h1>{{ countdown }}</h1>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
export default {
  name: 'rechargeBox',
  data() {
    return {
      countdown: '',
      totalSeconds: 60
    }
  },

  methods: {
    //生产二维码
    useqrcode(url) {
      var canvas = document.getElementById('canvas')
      this.$QRCode.toCanvas(canvas, url, { width: 400 }) //直接修改二维码尺寸
    },
    startCountdown() {
      const interval = setInterval(() => {
        const seconds = this.totalSeconds % 60
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
        this.countdown = `将在${formattedSeconds}秒后返回主页`
        this.totalSeconds--
        if (this.totalSeconds < 0) {
          clearInterval(interval)
          this.countdown = '时间到！'
          this.$router.push('/home')
        }
      }, 1000)
    }
  },
  mounted() {
    let productId = this.$route.query.productId
    let cfrq = dayjs(this.$route.query.cfrq).format('YYYYMMDD')
    let xm = this.$route.query.xm
    let totalFee = this.$route.query.totalFee
    let url = `http://www.zqjfs.com/xx_weixin_zqjfs/qrCodeForMzjf.jsp?wxTradeType=JSAPI&productId=${productId}&totalFee=${totalFee}&cfrq=${cfrq}&xm=${xm}`
    console.log(url)

    this.useqrcode(url)
    this.startCountdown()
  }
}
</script>
<style lang="less" scoped>
.recharge {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 80px;
    font-weight: bold;
    padding: 20px;
  }
}
</style>
